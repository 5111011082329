import React from "react";

import Btn from "./Btn";
import StoreBadge from "./StoreBadge";
import PreviewPlayer from "../components/PreviewPlayer";

import { md } from "../styles/breakpoints";
import { blogMd, blogLg } from "../styles/breakpoints";
import { Inter2r } from "../styles/blog";
import Typography from "../components/Typography";

import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "tailwind.macro";

const styles = {
  bg: tw`bg-white`,
  wrap: tw`my-6 lg:my-10 py-8 max-w-lg mx-auto px-5`,
  text: css`
    ${Inter2r};
    text-align: center;
    max-width: 50rem;
    margin: auto;
    margin-bottom: 40px;
  `,
  spacer: tw`my-8 sm:my-12`,
  badges: css`
    ${tw`flex flex-col items-center justify-center`}
    font-size: 18px;
    line-height: ${25 / 18};
    letter-spacing: -0.75px;

    @media (${md}) {
      flex-direction: row;
    }
  `,
  iosBadge: css`
    ${tw`m-2`}
    width: 170px;

    @media (${md}) {
      width: 150px;
    }
  `,
  link: css`
    margin: 0 0.5ch;
    font-weight: 700;
    text-decoration: none;
    color: inherit;
    border-bottom: 2px solid #1d1d1d;

    &:hover {
      border-bottom-color: transparent;
    }
  `,
  androidBadge: css`
    ${tw`m-2`}
    width: 170px;

    @media (${md}) {
      width: 166px;
    }
  `,
};

const WebappBadges = () => (
  <div
    css={css`
      ${tw`hidden md:block`}
    `}
  >
    <Btn
      css={tw`mb-10`}
      center
      href="https://masterclass.ted.com/web/"
      label="Get Started"
    />
    <p css={styles.badges}>
      Also available in the <StoreBadge ios textLink css={styles.link} /> and{" "}
      <StoreBadge android textLink css={styles.link} />
    </p>
  </div>
);

const AppBadges = () => (
  <div
    css={css`
      ${styles.badges};
      ${tw`md:hidden`}
    `}
  >
    <StoreBadge ios imgProps={{ width: 170 }} css={styles.iosBadge} />
    <StoreBadge android imgProps={{ width: 170 }} css={styles.androidBadge} />
  </div>
);

const Wrap = styled.div`
  ${({ bg }) => `background-color: ${bg || "transparent"}`}
`;

const CoursePreviewBlock = ({
  bg,
  children,
  title,
  tagline,
  ctaText,
  hideCTA,
  id,
  playerProps,
}) => (
  <Wrap bg={bg} id={id}>
    <div css={styles.wrap}>
      <Typography
        weight="bold"
        align="center"
        variant="Inter-1"
        mb={4}
        as="h2"
        responsive={[
          { size: blogMd, variant: "Inter2" },
          { size: blogLg, variant: "Inter4" },
        ]}
      >
        {title}
      </Typography>
      <p css={styles.text}>{tagline}</p>

      <div css={styles.spacer}>
        <PreviewPlayer {...playerProps} />
      </div>

      {!hideCTA && (
        <>
          <p css={styles.text}>{ctaText}</p>
          <AppBadges />
          <WebappBadges />
        </>
      )}

      {children}
    </div>
  </Wrap>
);

CoursePreviewBlock.defaultProps = {
  bg: "#fff",
  title: "Course preview",
  tagline: `Head of TED, Chris Anderson will guide you through 11 lessons culminating in your very own TED-style talk.`,
  ctaText: "Are you ready to start learning? Check out the first lesson!",
  hideCTA: false,
};

export default CoursePreviewBlock;
