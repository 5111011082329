import React from "react";

import appStoreBadge from "../images/apple-store-badge.svg";
import googlePlayBadge from "../images/google-play-badge.svg";

export const AppleBadge = ({ imgProps, textLink, ...props }) => (
  <a
    data-gtm={!textLink ? "app-store-badge" : "app-store-link"}
    href="https://itunes.apple.com/us/app/ted-masterclass/id1473601397"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {!textLink && (
      <img alt="Download on the App Store" src={appStoreBadge} {...imgProps} />
    )}
    {textLink && "App Store"}
  </a>
);

export const GoogleBadge = ({ imgProps, textLink, ...props }) => (
  <a
    data-gtm={!textLink ? "google-play-badge" : "app-store-link"}
    href="https://play.google.com/store/apps/details?id=com.ted.B2CMasterclass"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {!textLink && (
      <img alt="Get it on Google Play" src={googlePlayBadge} {...imgProps} />
    )}
    {textLink && "Google Play Store"}
  </a>
);

const StoreBadge = ({ ios, android, ...props }) => {
  return (
    (ios && <AppleBadge {...props} />) ||
    (android && <GoogleBadge {...props} />) || <></>
  );
};

export default StoreBadge;
